/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
}

a, button {
    word-wrap: break-word;
    overflow-wrap: break-word;
}
